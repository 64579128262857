import React from 'react'
import { Container, ImageTitleCol  } from './styles'
import TextParagraph from '../../../../ui/TextParagraph';
// import Scene from '../../../../three_scenes/scene';
import PropTypes from 'prop-types';
import Img from "gatsby-image"
function HowItWorks(props) {
    const { collection, replacement, accumulation } = props;
    return (
        <Container>
                <ImageTitleCol>
                    <Img
                        className="headshot"
                        fixed={collection}
                        alt="headshot"

                    />
                    <TextParagraph
                        header="1. Collection"
                        body="After a tenant fills up the dish, garbage, or recycling container we send in our lift robot into their apartment to retreive the used and filled up containers."
                    />
                </ImageTitleCol>
                <ImageTitleCol>
                    <Img
                        className="headshot"
                        fixed={replacement}
                        alt="headshot"
                    />
                    <TextParagraph
                        header="2. Replacement"
                        body="Our tenant will need new containers to replace their old one’s so we’ll ferry over fresh containers with our carrier robot. It will also help bring the used containers back for processing "
                    />
                </ImageTitleCol>
                <ImageTitleCol>
                    <Img
                        className="headshot"
                        fixed={accumulation}
                        alt="headshot"
                    />
                    <TextParagraph
                        header="3. Accumulation"
                        body="Over time our carrier robot will accumulate stacks of used containers that need to be processed. (garbage bins emptied & re-lined and dishes cleaned)"
                    />
                </ImageTitleCol>

        </Container>
    )
}
HowItWorks.propTypes = {
    collection: PropTypes.any,
    replacement: PropTypes.any,
    accumulation: PropTypes.any,
}

export default HowItWorks