import React from 'react';
import InvestorMain from '../components/Main/InvestorMain';
import { graphql } from "gatsby"
import PropTypes from 'prop-types';
class Team extends React.Component {
    render() {
        return (
            <InvestorMain images={this.props.data} />
        );
    }
}

Team.propTypes = {
    data: PropTypes.any
}
export default Team

export const pageQuery = graphql`
query {
    header: file(relativePath: { eq: "cover.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    botPair: file(relativePath: { eq: "robot_set.png" }) {
      childImageSharp {
        fixed (height: 250){
          ...GatsbyImageSharpFixed
        }
        
      }
    }
    apartment: file(relativePath: { eq: "apartment.png" }) {
      childImageSharp {
        fixed(height: 250) {
          ...GatsbyImageSharpFixed
        }
        
      }
    }
    collection: file(relativePath: { eq: "collection.png" }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed
        }
        
      }
    }
    accumulation: file(relativePath: { eq: "accumulation.png" }) {
      childImageSharp {
        fixed(height: 200) {
          ...GatsbyImageSharpFixed
        }
        
      }
    }
    replacement: file(relativePath: { eq: "robot_set.png" }) {
      childImageSharp {
        fixed (height: 200){
          ...GatsbyImageSharpFixed
        }
        
      }
    }
    carrier: file(relativePath: { eq: "carrier_bot.png" }) {
      childImageSharp {
        fixed(height: 150) {
          ...GatsbyImageSharpFixed
        }
        
      }
    }
    contract: file(relativePath: { eq: "contract_worker.png" }) {
      childImageSharp {
        fixed(height: 150) {
          ...GatsbyImageSharpFixed
        }
        
      }
    }   
}
`