import React from 'react'
import { Container, ImageTitleCol, LeftStuff, SubHeader, Header} from './styles'
import TextParagraph from '../../../../ui/TextParagraph';
// import Scene from '../../../../three_scenes/scene';
import PropTypes from 'prop-types';
import Img from "gatsby-image"
function WhatIsIt(props) {
    const { botsImage, apartmentImage } = props;
    // console.log(props);
    botsImage; apartmentImage;
    return (
        <Container>
            <LeftStuff>
                <ImageTitleCol>
                <Img
                    className="headshot"
                    fixed={botsImage}
                    alt="headshot"
                />
                <SubHeader>2 service robots</SubHeader>
                </ImageTitleCol>
                <Header>housekeep</Header>
                <ImageTitleCol>
                <Img
                    className="headshot"
                    fixed={apartmentImage}
                    alt="headshot"
                />
                <SubHeader>(<u>~150</u> apartment units)</SubHeader>
                </ImageTitleCol></LeftStuff>
            <TextParagraph
                header="Shared Robotic Housekeepers"
                body="One set of housekeeping robots services many apartment units. This gives us the two key advantages of distribution of cost, standardization of space."
            />

        </Container>
    )
}
WhatIsIt.propTypes = {
    botsImage: PropTypes.any,
    apartmentImage: PropTypes.any,
}

export default WhatIsIt