import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    padding-top: 90px;
    padding-bottom: 90px;
    align-items: flex-start;

    @media screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: center;

        div {
              padding-bottom: 10px;
        }
        
      }
`

export const LeftStuff = styled.div`
      display: flex;
      align-items: center;
`

export const ImageTitleCol = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      
      width: 95%;
    
`
export const SubHeader = styled.p`
font-family: "Open Sans", sans-serif;
font-style: normal;
font-weight: normal;
font-size: 20px;
color: #828282;

`
export const Header = styled.p`
font-family: "Open Sans", sans-serif;
font-style: normal;
font-weight: bold;
font-size: 20px;
line-height: 36px;

/* Blue 1 */

color: #000000;

`
