import React from 'react';
import PropTypes from 'prop-types';
import { Header, TitleImage, Footer, DropDownable } from '../../ui';
// import { Stickyroll } from '@stickyroll/stickyroll';
// import ConsumerSideBySide from '../../ui/ConsumerSideBySide';
import { WhatIsIt, HowItWorks, HumanAssisted } from './components';
import Warning from '../../ui/Warning';


const InvestorMain = props => {
  const images = props.images;
  return (
    <div style={{ maxWidth: '1500px', margin: 'auto', paddingLeft: '20px' }}>
      <Warning />
      <div style={{ height: '100vh', scrollSnapAlign: 'start'}}>
        <Header />
        <TitleImage imageSrc={images.header.childImageSharp.fluid} alt="Robot in apartment" loading="eager" />
      </div>
      <DropDownable title="What is it?" defaultState={true}>
        <WhatIsIt botsImage={props.images.botPair.childImageSharp.fixed}
          apartmentImage={props.images.apartment.childImageSharp.fixed} />
      </DropDownable>
      <DropDownable title="How it works:" defaultState={true}>
        <HowItWorks collection={props.images.collection.childImageSharp.fixed}
          accumulation={props.images.accumulation.childImageSharp.fixed}
          replacement={props.images.replacement.childImageSharp.fixed} />
      </DropDownable>
      <DropDownable title="Human Assisted:" defaultState={true}>
        <HumanAssisted carrier={props.images.carrier.childImageSharp.fixed}
          contract={props.images.contract.childImageSharp.fixed} />
      </DropDownable>
      <Footer />
    </div>
  )
}

InvestorMain.propTypes = {
  images: PropTypes.any
}

export default InvestorMain;
