import React from 'react'
import { Container, ImageTitleCol, LeftStuff, SubHeader } from './styles'
import TextParagraph from '../../../../ui/TextParagraph';
// import Scene from '../../../../three_scenes/scene';
import PropTypes from 'prop-types';
import Img from "gatsby-image"
function HumanAssisted(props) {
    const { carrier, contract } = props;
;
    return (
        <Container>
            <LeftStuff>
                <ImageTitleCol>
                <Img
                    className="headshot"
                    fixed={carrier}
                    alt="headshot"
                />
                <SubHeader>Carrier robot fills up depot space over time.</SubHeader>
                </ImageTitleCol>
                <img src="arrow_back.svg"/>
                <ImageTitleCol>
                <Img
                    className="headshot"
                    fixed={contract}
                    alt="headshot"
                />
                <SubHeader>A contractor comes to process dishes, garbage, and recycling</SubHeader>
                </ImageTitleCol></LeftStuff>
            <TextParagraph
                header="Delegated Complexity"
                body="For now some tasks are really tricky for a robot to do. Rather than having robots fidling aroud with garbage bags and struggling to grasp dishes and cutlery we instead offload these tasks to a capable contracted worker."
            />

        </Container>
    )
}
HumanAssisted.propTypes = {
    carrier: PropTypes.any,
    contract: PropTypes.any,
}

export default HumanAssisted